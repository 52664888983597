<template>
  <div class="bg-white p-5">
    <el-form
      label-position="top"
      size="medium"
    >
      <el-form-item label="推广对象">
        <el-radio-group
          :value="promotionObjectType"
          @input="(val) => onChange(val, 'promotionObjectType')"
        >
          <el-radio-button
            v-for="promotionObjectType in enumConstants.promotionObjectTypes"
            :label="promotionObjectType.key"
            :key="promotionObjectType.key"
            >{{ promotionObjectType.value }}</el-radio-button
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="推广目标">
        <el-radio-group
          :value="objective"
          @input="(val) => onChange(val, 'objective')"
        >
          <el-radio-button
            v-for="objective in enumConstants.objectives"
            :label="objective.key"
            :key="objective.key"
            >{{ objective.value }}</el-radio-button
          >
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    promotionObjectType: String, // // 推广对象
    objective: String, // 推广目标
    enumConstants: Object,
  },
  data() {
    return {};
  },
  methods: {
    onChange(val, key) {
      this.$emit(`update:${key}`, val);
    },
  },
};
</script>
