<template>
  <div>
    <el-dialog
      title="确认要提交吗?"
      :visible="creatFormShow"
      width="600px"
      append-to-body
      :modal-append-to-body="false"
    >
      <div>
        <el-form
          :model="params"
          ref="ruleForm"
          label-width="200px"
          class="demo-ruleForm"
        >
          <!-- <el-form-item
            label="是否立即上传："
            prop="resource"
          >
            <el-switch
              v-model="params.is_upload"
              :active-value="true"
              :inactive-value="false"
            >
            </el-switch>
          </el-form-item> -->
          <el-form-item
            label="上传之后广告的投放状态："
            prop="status"
          >
            <el-switch
              v-model="params.status"
              active-value="ACTIVE"
              inactive-value="PAUSED"
            >
            </el-switch>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="cancel">取 消</el-button>
        <el-button
          type="primary"
          @click="confirm"
          id="creatAdEvent"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    creatFormShow: Boolean,
  },
  data() {
    return {
      params: {
        status: 'ACTIVE',
      },
    };
  },
  methods: {
    cancel() {
      this.$emit('update:creatFormShow', false);
    },
    confirm() {
      this.$emit('creatAd', this.params);
    },
  },
};
</script>
<style lang="scss" scoped></style>
