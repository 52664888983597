<template>
  <div>
    <div class="flex h-10 items-center justify-start overflow-hidden w-full bg-white">
      <div
        v-for="(item, index) in list"
        :key="item"
        class="flex items-center h-full relative"
        :class="{
          'bg-white': index >= current - 1,
          'bg-primary1': index < current - 1,
        }"
      >
        <div
          class="px-4 h-full flex items-center"
          :class="{
            'bg-white': index >= current,
            'bg-primary1': index < current,
            'text-[#ffffff]': index < current,
          }"
        >
          {{ item }}
        </div>
        <div class="arrowDown border-s-primary"></div>
        <div
          class="arrowDownContent"
          :class="{
            'border-s-white': index >= current,
            'border-s-primary1': index < current,
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    current: Number,
  },
  data() {
    return {
      list: ['广告系列', '选择受众', '创意与投放位', '预算排期与预览'],
    };
  },
};
</script>

<style lang="scss">
.arrowDown {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #409eff;
  position: relative;
}
.arrowDownContent {
  position: absolute;
  height: 0;
  width: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left-width: 20px;
  border-left-style: solid;
  right: 5px;
  top: 0px;
}
</style>
