var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex h-10 items-center justify-start overflow-hidden w-full bg-white"},_vm._l((_vm.list),function(item,index){return _c('div',{key:item,staticClass:"flex items-center h-full relative",class:{
        'bg-white': index >= _vm.current - 1,
        'bg-primary1': index < _vm.current - 1,
      }},[_c('div',{staticClass:"px-4 h-full flex items-center",class:{
          'bg-white': index >= _vm.current,
          'bg-primary1': index < _vm.current,
          'text-[#ffffff]': index < _vm.current,
        }},[_vm._v(" "+_vm._s(item)+" ")]),_c('div',{staticClass:"arrowDown border-s-primary"}),_c('div',{staticClass:"arrowDownContent",class:{
          'border-s-white': index >= _vm.current,
          'border-s-primary1': index < _vm.current,
        }})])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }