<template>
  <div class="bg-white">
    <el-tabs
      v-model="current"
      type="card"
    >
      <el-tab-pane
        v-for="(creative, index) in value"
        :key="index"
        :name="index + ''"
      >
        <div slot="label">
          创意{{ index + 1 }}
          <!-- <i class="el-icon-circle-close ml-2 text-zinc-800 hover:text-primary"></i> -->
        </div>
        <div class="m-6">
          <el-form
            label-width="100px"
            :model="creative"
            :rules="rules"
            ref="form"
          >
            <el-form-item label="品牌名称">
              <el-input
                disabled
                :value="creative.brandName"
              ></el-input>
            </el-form-item>
            <el-form-item label="广告样式">
              <el-tabs
                type="border-card"
                v-model="creative.type"
              >
                <el-tab-pane
                  label="单一图像或视频"
                  name="WEB_VIEW"
                >
                  <el-form-item
                    class="!mb-6"
                    prop="infos"
                  >
                    <upload
                      action="#"
                      accept=".jpg,.png,.mp4,.mov"
                      multiple
                      :show-file-list="false"
                      :API="API"
                      :value.sync="creative.infos"
                      :limit="10"
                      list-type="picture-card"
                      :selectLimit="selectMediaLimit"
                      @change="(list) => onChangeImg(index, list)"
                      :selectImgIndex.sync="selectImgIndex"
                      class="mb-6 uploadSnapchat"
                    >
                      <div
                        class="leading-4 text-xs border w-full h-full flex flex-col justify-center items-center rounded-md"
                      >
                        <el-button type="primary">点击上传</el-button>
                        <div class="text-gray-700 leading-4 mt-1">
                          <div>尺寸：1080 x 1920</div>
                          <div>视频必须使用 H.264 编码</div>
                          <div>且时长不得超过 180 秒</div>
                          <div>图像格式必须为PNG或JPEG</div>
                        </div>
                      </div>
                    </upload>
                    <el-input
                      v-if="creative.infos.length > 0"
                      placeholder="请输入标题"
                      maxlength="34"
                      show-word-limit
                      v-model="creative.infos[selectImgIndex || 0].headline"
                      @change="(value) => onChangeHeadline(value, index)"
                    ></el-input>
                  </el-form-item>
                </el-tab-pane>
              </el-tabs>
            </el-form-item>

            <el-form-item
              label="行动号召"
              prop="callToAction"
            >
              <el-select v-model="creative.callToAction">
                <el-option
                  v-for="callToAction in enumConstants.callToActions"
                  :key="callToAction.key"
                  :label="callToAction.value"
                  :value="callToAction.key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="推广网址"
              prop="webViewUrl"
            >
              <div class="flex">
                <el-input
                  v-model="creative.webViewUrl"
                  disabled
                  class="flex-1"
                ></el-input>
                <a
                  :href="creative.webViewUrl"
                  target="_blank"
                  class="ml-6"
                >
                  <el-button type="text">预览</el-button>
                </a>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { upload } from '@adCommonComponent';
import { uploadSingle } from '@/api/common.js';
import getImageSize from '@adCommonComponent/utils/getImageSize.js';
import getVideoMes from '@adCommonComponent/utils/getVideoMes.js';
export default {
  components: { upload },
  props: {
    value: Array,
    data: Object,
    enumConstants: {},
  },
  data() {
    return {
      API: uploadSingle,
      current: '0',
      selectImgIndex: null,
      rules: {
        infos: [
          {
            validator: this.validatorMedia,
            trigger: 'change',
          },
        ],
        callToAction: [
          {
            required: true,
            message: '请选择行动号召',
          },
        ],
      },
    };
  },
  methods: {
    selectMediaLimit(file) {
      return new Promise((relsove, reject) => {
        const raw = file.raw;
        const imgTypes = ['image/png', 'image/jpg', 'image/jpeg'];
        const videoType = ['video/mp4'];
        const fileSize = raw.size / 1024 / 1024;
        // 格式限制
        if (imgTypes.includes(raw.type)) {
          // 图片
          const isLt5MB = fileSize <= 5;
          if (!isLt5MB) {
            return reject('文件限制在5MB以内');
          }

          getImageSize(file.url)
            .then((res) => {
              const { width, height } = res;
              if (width != 1080 || height != 1920) {
                return reject('请选择1080*1920比例的图片');
              }
              relsove();
            })
            .catch((err) => {
              console.log('err', err);
            });
        } else if (videoType.includes(raw.type)) {
          // 视频
          const isLt1GB = fileSize <= 1024;
          if (!isLt1GB) {
            return reject('文件限制在1GB以内');
          }
          getVideoMes(file)
            .then((res) => {
              const { isVideoH264, duration, videoWidth, videoHeight } = res;
              if (videoWidth != 1080 || videoHeight != 1920) {
                return reject('请选择1080*1920比例的视频');
              }
              if (!isVideoH264) {
                return reject('请上传H.264 编码视频');
              }
              if (duration < 3 || duration > 180) {
                return reject('请上传时长在3-180秒中间视频');
              }

              relsove();
            })
            .catch(() => {
              return reject('文件加载失败');
            });
        } else {
          reject('文件格式不正确');
        }
      });
    },
    validatorMedia(rule, value, callback) {
      if (!value || !value.length) {
        callback('请上传素材');
      } else if (!value[0].headline) {
        callback(new Error('请填写标题'));
      } else {
        const status = Array.from(new Set(value.map((item) => item.status)));
        if (status.length == 1 && status.includes('success')) {
          callback();
        } else {
          callback(new Error('存在上传中或上传失败的素材'));
        }
      }
    },
    // 对外提供的验证
    validate() {
      let list = this.$refs.form.map((item) => item.validate());
      return Promise.all(list);
    },
    onChangeHeadline(val, index) {
      if (this.selectImgIndex === null) {
        this.value[index].infos.map((item) => {
          this.$set(item, 'headline', val || '');
        });
      } else {
        this.value[index].infos.map((item) => {
          if (!item.headline) {
            this.$set(item, 'headline', val || '');
          }
        });
      }
    },
    onChangeImg(index) {
      let isNoStatusSuccess = [];
      this.value[index].infos.map((item) => {
        if (!item.headline) {
          this.$set(item, 'headline', this.value[index].infos[0].headline || '');
        }
        if (item.status != 'success') {
          isNoStatusSuccess.push(item);
        }
      });
      if (isNoStatusSuccess.length == 0) {
        this.$refs.form[0].validateField(['infos']);
      }
    },
  },
};
</script>

<style lang="scss">
.uploadSnapchat {
  .el-upload--picture-card {
    width: 150px;
    height: 240px;
  }
}
</style>
